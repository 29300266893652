import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

type props = {
  text: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}
export default function LocalButton(props) {
  return (
    <Stack spacing={2} direction="row">
      <Button variant="contained" onClick={props.onClick}>{props.text}</Button>
    </Stack>
  );
}