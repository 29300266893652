import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

type Props = {
  setText: React.Dispatch<React.SetStateAction<string>>
  label: string
}
export default function LocalInput(props: Props) {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="outlined-basic" label={props.label} variant="outlined" onChange={(e) => props.setText(e.currentTarget.value)}/>
    </Box>
  );
}