import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LocalButton from '../component/LocalButton';
import users from '../ds/users.json';
// import { TwilioService } from '../twilio';

function generate(element: React.ReactElement) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function Dashboard() {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  const handleMouseEvent = (phone: string) => {
    // Do something
    const fetchData = async () => {
        const response = await fetch(
            `https://alg.stepin.app/stock/send/${phone}`
        )
        console.log(response)
    }

    fetchData().catch(console.error)
  };

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Avatar with text and icon
          </Typography>
          <Demo>
            <List dense={dense}>
                {users.map((item, index) => {
                    return (
                        <ListItem secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                                <LocalButton text={'Send OTP'} onClick={handleMouseEvent(item.phone)}/>
                            </IconButton>
                            }>
                            <ListItemText
                            primary={item.name}
                            secondary={item.phone}
                            />
                        </ListItem>
                    )
                })}
            
            </List>
          </Demo>
        </Grid>
      </Grid>
    </Box>
  );
}
