import React from 'react';
import LocalButton from '../component/LocalButton';
import LocalInput from '../component/LocalInput';

function CreateUser() {
  const [currentText, setText] = React.useState<string>('')
  const [currentUser, setUser] = React.useState<string>('')

  const handleMouseEvent = (e: React.MouseEventHandler<HTMLButtonElement>) => {
    // Do something
    console.log("hello world")
  };

  return (
    <div className="App" style={{padding: '20px'}}>
      <LocalInput label='Username' setText={setUser}/>
      <LocalInput label='Phone number' setText={setText}/>
      Your pphone number is: + 61 {currentText} and using the name: {currentUser} <br/><br/>
      <LocalButton text={'Add User'} onClick={handleMouseEvent}/>
    </div>
  );
}

export default CreateUser;
